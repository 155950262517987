import React, { lazy, Suspense, useRef } from "react";
import Loading from "./components/Loading/Loading";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ToTop from "./components/ToTop";
import { Link } from "./types";

const LandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const ArenaPage = lazy(() => import("./pages/ArenaPage"));
const GenesisPage = lazy(() => import("./pages/GenesisPage"));
const MarketplacePage = lazy(() => import("./pages/MarketplacePage"));
const CatacombsPage = lazy(() => import("./pages/CatacombsPage"));
const KocPage = lazy(() => import("./pages/KocPage/KocPage"));
const NoRoutePage = lazy(() => import("./pages/NoRoutePage"));

function App() {

  const refHeader = useRef();

  const links: Link[] = [
    { name: "Home", href: "/", component: <LandingPage refHeader={refHeader} /> },
    { name: "Arena", href: "/arena", component: <ArenaPage />, },
    { name: "Genesis", href: "/genesis", component: <GenesisPage />, },
    { name: "Marketplace", href: "/marketplace", component: <MarketplacePage /> },
    { name: "Catacombs", href: "/catacombs", component: <CatacombsPage /> },
    { name: "KoC", href: "/koc", component: <KocPage refHeader={refHeader} />, highlighted: true },
    {
      name: "Articles",
      href: "https://medium.com/@GladiatorDeFi",
      target: "_blank",
      rel: "noopener noreferrer",
    },
    {
      name: "Docs",
      href: "https://gladiator-protocol.gitbook.io/gladiator-protocol/T8QENNDx7YQm9CkauSpA/",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  const Fallback = (): JSX.Element => {
    return (
      <div className="container mx-auto px-5 flex justify-center bg-lightblue">
        <Loading />
      </div>
    )
  }

  return (
    <Router>
      <Header links={links} refHeader={refHeader} />
      <Routes>
        {links.filter((l, i) => i < links.length - 2).map((link, i) => {
          return (
            <Route key={i.toString()} path={link.href} element={<Suspense fallback={<Fallback />}>{link.component}</Suspense>} />
          )
        })}
        <Route path="*" element={<Suspense fallback={<Fallback />}><NoRoutePage /></Suspense>} />
      </Routes>
      <Footer links={links} />
      <ToTop />
    </Router>
  );
}

export default App;
