import useMediaQuery from "../hooks/useMediaQuery";
import { LegacyRef, useEffect, useState } from "react";
import { useWallet } from "../ethereum/ethereum";
import ImgNextGen from "./ImgNextGen";
import { useLocation } from "react-router-dom";
import CustomBtn from "./CustomBtn/CustomBtn";
import { BtnType, Link, Position, Size } from "../types";
import Svg from "./Svg";
import classes from "./CustomBtn/CustomBtn.module.scss";
import logo_with_text_lg from "../assets/images/header/logo_with_text_lg.png";
import logo_with_textWebp_lg from "../assets/images/header/logo_with_text_lg.webp";
import logo_with_textJp2_lg from "../assets/images/header/logo_with_text_lg.jp2";
import logo_with_textJxr_lg from "../assets/images/header/logo_with_text_lg.jxr";
import logo_with_text_sm from "../assets/images/header/logo_with_text_sm.png";
import logo_with_textWebp_sm from "../assets/images/header/logo_with_text_sm.webp";
import logo_with_textJp2_sm from "../assets/images/header/logo_with_text_sm.jp2";
import logo_with_textJxr_sm from "../assets/images/header/logo_with_text_sm.jxr";
import logo_with_text_sm_2x from "../assets/images/header/logo_with_text_sm_2x.png";
import logo_with_textWebp_sm_2x from "../assets/images/header/logo_with_text_sm_2x.webp";
import logo_with_textJp2_sm_2x from "../assets/images/header/logo_with_text_sm_2x.jp2";
import logo_with_textJxr_sm_2x from "../assets/images/header/logo_with_text_sm_2x.jxr";
import EthereumInteraction from "../ethereum/EthereumInteraction";
import CustomTooltip from "./CustomTooltip";
import hot from "../assets/images/hot.svg";

interface IHeader {
    links: Link[];
    refHeader: any;
}

export default function Header({ links, refHeader }: IHeader,): JSX.Element {
    const bp320px = useMediaQuery(320);
    const bp370px = useMediaQuery(370);
    const bp1538px = useMediaQuery(1538);

    const { wallet, chain, loaded } = useWallet();

    const location = useLocation();
    const activeLink = links.findIndex(link => link.href === location.pathname);

    const [collapsed, setCollapsed] = useState(true);
    const [tooltip, setTooltip] = useState("Copy to clipboard");

    useEffect(() => {
        if (bp1538px && !collapsed) setCollapsed(true);
    }, [bp1538px]);

    const displayLinks = (inCollapse?: boolean) => {
        return links.map((link, i) => {
            return (
                <div key={i.toString()} className={"flex justify-center"}>
                    <div className={`relative w-fit ${inCollapse ? "flex justify-center mb-2" : ""}`}>
                        <a
                            href={link.href}
                            className={`flex justify-center items-center
                            text-18 font-medium 
                                ${!link.disabled
                                    ? link.highlighted
                                        ? "text-flame hover:brightness-125"
                                        : "hover:text-orange"
                                    : "cursor-not-allowed"} 
                                ${link.highlighted ? "focus:brightness-125" : "focus:text-orange "}
                                ${activeLink === i
                                    ? link.highlighted
                                        ? "text-flame"
                                        : "text-orange"
                                    : !link.highlighted && "text-black opacity-40"}
                                    transition duration-300`}
                            target={link.target}
                            rel={link.rel}
                        >
                            {link.highlighted &&
                                <img src={hot} alt="" width={15} height={19.97} className={"mr-1"} />
                            }
                            {link.name}
                        </a>
                        {activeLink === i &&
                            <span className={`absolute w-full 
                            border-b-2 ${link.highlighted ? "border-flame" : "border-orange"} border-solid 
                            left-0 bottom-0 2xl:bottom-[-30px]`} />
                        }
                    </div>
                </div>
            );
        });
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(wallet);
        setTooltip("Copied!");
    }

    const displayWallet = () => {
        return (
            <EthereumInteraction
                wallet={wallet}
                chain={chain}
                loaded={loaded}
                className={"min-w-[215px] min-h-[45px]"}
                connectButton={
                    <CustomBtn size={Size.sm} type={BtnType.outlined} inverseColor={"lightblue"} className={"px-[24px]"}>
                        <Svg name="wallet" width={29.02} height={24} className={"mr-3"} />
                        Connect wallet
                    </CustomBtn>}
                chainSwitchButton={
                    <CustomBtn size={Size.sm} type={BtnType.outlined} inverseColor={"lightblue"} className={"px-[24px]"}>
                        Switch to Avalanche
                    </CustomBtn>
                }
            >
                <CustomTooltip
                    tooltip={tooltip}
                    onInvisible={() => setTooltip("Copy to clipboard")}
                    position={Position.bottom}
                    wallet={wallet}
                >
                    <CustomBtn
                        size={Size.sm}
                        type={BtnType.outlined}
                        inverseColor={"lightblue"}
                        className={"px-[24px]"}
                        onClick={copyToClipboard}
                    >
                        <Svg name="wallet" width={29.02} height={24} className={"mr-3"} />
                        {wallet?.slice(0, 4)}...{wallet?.slice(-2)}
                    </CustomBtn>
                </CustomTooltip>
            </EthereumInteraction>
        )
    }

    return (
        <header ref={refHeader} className="fixed top-0 left-0 right-0 z-50 bg-lightblue border-b border-lightsky border-solid">
            <div className={`container mx-auto p-5 flex space-x-5 justify-between items-center`}>
                <a href="/" className="text-black text-22 font-medium">
                    <ImgNextGen
                        srcWebp={`${logo_with_textWebp_sm} 210w, ${logo_with_textWebp_lg} 260w, ${logo_with_textWebp_sm_2x} 420w`}
                        srcJp2={`${logo_with_textJp2_sm} 210w, ${logo_with_textJp2_lg} 260w, ${logo_with_textJp2_sm_2x} 420w`}
                        srcJxr={`${logo_with_textJxr_sm} 210w, ${logo_with_textJxr_lg} 260w, ${logo_with_textJxr_sm_2x} 420w`}
                        fallback={`${logo_with_text_sm} 210w, ${logo_with_text_lg} 260w, ${logo_with_text_sm_2x} 420w`}
                        src={logo_with_text_lg}
                        sizes={"(min-width: 370px) 260px, 210px"}
                        width={bp370px ? 260 : bp320px ? 210 : "100%"}
                        height={bp370px ? 44 : bp320px ? 35 : "auto"}
                        alt={""}
                        lazyLoadNotNeeded
                    />
                </a>
                <div className="flex justify-between space-x-8">
                    {bp1538px
                        ?
                        <>
                            <nav className="flex justify-between space-x-8 items-center">{displayLinks()}</nav>
                            {displayWallet()}
                        </>
                        :
                        <div
                            className={
                                `w-[50px] h-fit cursor-pointer 
                                hover:bg-orange hover:bg-opacity-[0.15] opacity-40 hover:opacity-100 
                                px-[10px] py-[7px] rounded-full
                                ${classes.rippleForNavigationBtn}`
                            }
                            onClick={() => setCollapsed(!collapsed)}
                        >
                            <div>
                                {[0, 1, 2].map((elem, i) =>
                                    <div key={i.toString()} className={"w-[100%] h-[3px] bg-orange my-[6px]"} />
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={`flex justify-center h-0 truncate transition-[height] duration-[350ms] ease-in-out ${!collapsed ? "h-[440px]" : ""}`}>
                <div>
                    <nav className="">{displayLinks(true)}</nav>
                    {displayWallet()}
                </div>
            </div>
        </header>
    );
}